import { Button, Dialog, Grid, Typography, Box } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import Menu from "../../../../../../components/Menu";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Nachhaltigkeit/3.webp";
import { useState } from "preact/hooks";
import { PDFViewer } from "playbook-pwa-ui";

export default function EcoVadis() {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  };
  const openDialog = () => {
    setOpen(true);
  };

  return (
    <>
      <Dialog fullScreen onClose={closeDialog} open={open}>
        <Menu absolute closeEvent={closeDialog} icon={{ image: "none" }} />
        <PDFViewer path="../../../../../assets/_Chapters/Nachhaltigkeit/PDF/Zertifikat-SSV-Kroschke-ISO-14001-2015-de.pdf" />
      </Dialog>
      <Slide
        background={{
          image: BackgroundImage,
          width: "50dvw",
          align: "right",
          position: "right",
        }}
      >
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={6}>
            <QHeader title="Wir denken weiter" />
            <Typography variant="body1">
              Wir sind uns unseres Einflusses auf die Umwelt bewusst und
              versuchen diesen kontinuierlich zu verbessern.
            </Typography>
            <Box marginTop="20px">
              <Button variant="contained" color="primary" onClick={openDialog}>
                ISO Zertifikat
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Slide>
    </>
  );
}
