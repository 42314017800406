import { Grid, Box, makeStyles } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";
import DieOptimaleNutzdauerSVG from "./_Components/DieOptimaleNutzdauerSVG";

const useStyles = makeStyles((theme) => ({
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
}));

export default function DieOptimaleNutzdauer() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid
        container
        spacing={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <QHeader
            title="Die optimale Nutzdauer für Schutzhandschuhe"
            subTitle="Das Reinigen und Wiederverwenden von Schutzhandschuhen reduziert:"
          />
          <QList
            listStyle="blatt"
            items={[
              <>
                CO<sub>2</sub>
              </>,
              "Rohstoffverbrauch",
              "Müll",
            ]}
          />
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.heightCorrection}>
            <DieOptimaleNutzdauerSVG height="100%" />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
