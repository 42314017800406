import Slide from "../../../../../../components/Slide";
import QList from "../../../../../../components/QList";
import OptimierungSVG from "./_Components/OptimierungSVG";
import { Box, Grid, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  fontRegular: {
    marginTop: "5px",
    fontWeight: 400,
  },
  heightCorrection: {
    display: "flex",
    justifyContent: "center",
    height: "calc(100dvh - 90px * 2)",
  },
  mb2: {
    marginBottom: "16px",
  },
}));
export default function Optimierung() {
  const classes = useStyles();
  return (
    <Slide>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <QList
            listStyle="blatt"
            fontSize="large"
            items={[
              <>
                <Box marginBottom="16px">
                  <b>
                    Beratung und Unterstützung bei der Auswahl von nachhaltigen
                    Produkten{" "}
                  </b>
                  <span className={classes.fontRegular}>
                    sowie die Vereinheitlichung Ihres Sortiments und Erstellung
                    von Hand- und Hautschutzplänen mit nachhaltigen Produkten.
                  </span>
                </Box>
                <QList
                  fontSize="small"
                  headline={
                    <>
                      Nachhaltige Produkte zeichnen sich{" "}
                      <span className="nobr">z. B.</span> durch folgende
                      Kriterien aus:
                    </>
                  }
                  items={[
                    "Eine umweltschonende Verarbeitung der Rohstoffe durch geringeren Wasserverbrauch und Nutzung von regenerativem Strom.",
                    "Die Verwendung von biobasierten Fasern als Nebenprodukt der Zellstoff- und Holzindustrie.",
                    "Die Nutzung von recyclebarem Material, hergestellt aus Plastikabfällen.",
                  ]}
                />
              </>,
            ]}
          />
        </Grid>
        <Grid item xs={7}>
          <Box className={classes.heightCorrection}>
            <OptimierungSVG height="100%" />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
}
