import { Grid } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Nachhaltigkeit/1.jpg";

export default function NachhaltigArbeiten_1() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50dvw",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Nachhaltig arbeiten - wir machen es vor" />
          <QList
            listStyle="blatt"
            noPadding
            items={[
              "Investition in einen e-Fuhrpark mit integriertem Ladepark",
              "Wiederverwendung von Verpackungsmaterialien",
              <>
                Einsatz neuester Technik wie <span className="nobr">z. B.</span>{" "}
                stromsparende LEDs, Wärmepumpe, Photovoltaikanlage{" "}
                <span className="nobr">u. v. m.</span>
              </>,
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
