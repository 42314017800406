import { Grid, Link } from "@material-ui/core";
import Slide from "../../../../../../components/Slide";
import QHeader from "../../../../../../components/QHeader";
import QList from "../../../../../../components/QList";

import BackgroundImage from "../../../../../../assets/_Chapters/_Images/background/Nachhaltigkeit/2.jpg";

export default function NachhaltigArbeiten_2() {
  return (
    <Slide
      background={{
        image: BackgroundImage,
        width: "50%",
        align: "right",
      }}
    >
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={6}>
          <QHeader title="Nachhaltig arbeiten – wir machen es vor" />
          <QList
            listStyle="blatt"
            noPadding
            items={[
              "Versand in gebündelten Einheiten",
              "Realisierung eines nahezu papierlosen Arbeitsalltags",
              <>
                Übernahme sozialer Verantwortung in Form von Spenden an die
                Kroschke Kinderstiftung,{" "}
                <Link
                  target="_blank"
                  href="https://ssv-kroschke.de/unternehmen/stark-fur-die-region/"
                >
                  Sponsoring regionaler Vereine
                </Link>{" "}
                wie dem <span className="nobr">ThSV Eisenach</span> und
                Partnerschaft mit der Diako
              </>,
              <>
                Förderung von Projekten zum Schutz von Natur und Tieren wie{" "}
                <span className="nobr">z. B.</span> United Kids Foundations und
                Tierheime
              </>,
            ]}
          />
        </Grid>
      </Grid>
    </Slide>
  );
}
